.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*THIS IS CSS MADE AFTER INSTALLATION*/
/* Add this in your App.css or a separate CSS file */

.product-grid {
  display: flex;
  flex-wrap: wrap;
}

.product-card {
  border: 1px solid #ccc;
  margin: 15px;
  padding: 15px;
  width: calc(33.333% - 30px);
}

/* Header Styles */
header {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

header h1 {
  margin: 0;
}

header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

header nav ul li {
  margin-left: 20px;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

header nav ul li a:hover {
  text-decoration: underline;
}

/* Footer Styles */
footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 10px;
}

/* Product Listing */
.product-grid {
  display: flex;
  flex-wrap: wrap;
}

.product-box {
  flex: 1;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  text-align: center;
}

/* Buttons */
button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Shopping Cart */
.cart-list {
  list-style: none;
  padding: 0;
}

.cart-item {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

/* Login Form */
.login-form {
  max-width: 300px;
  margin: auto;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
}
