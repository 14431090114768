body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom CSS file or <style> tag */

.navbar-collapse.collapsing,
.navbar-collapse.show {
  background-color: white; /* or any color you prefer */
  border: 1px solid black; /* Adds a black border */

}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the brand container takes up the full height of the navbar */
}

.brand-logo {
  max-height: 6vh; /* Reduced from 70% to a fixed viewport height to ensure it fits */
  width: auto; /* Maintains the aspect ratio */
}

@media (max-width: 576px) {
  .navbar-brand {
    justify-content: flex-start; /* Aligns the logo to the start (left) on smaller screens */
  }
  .brand-logo {
    max-height: 8vh; /* Further reduces the height on smaller screens */
  }
}
.navbar {
  background-color: white;  
  border-bottom: 1px solid blue; 
  height: 12vh;
  font-size: 24pt;
  }

@media (max-width: 992px) {
  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-left: 0; /* Ensures padding doesn't affect centering */
  }

 
  .navbar-nav .nav-item,
  .navbar-nav .nav-link {
    width: 100%;
    display: block;
  }

  /* Ensuring !important is used to override any existing !important styles */
  .nav-cart-item {
    justify-content: center !important; /* Centers horizontally for flex items */
    align-items: center !important; /* Centers vertically for flex items */
    display: flex !important; /* Ensures display flex is applied */
  }

  /* Adjusts the anchor tag within the cart item if necessary */
  .nav-cart-item .nav-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0.5rem 1rem !important; /* Adjust padding as needed */
  }
}
