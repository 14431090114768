.carousel-container {
  padding-top: 8vh;
  padding-bottom: 5vh;
  text-align: center;
  width: 100%;
  background-color: white; /* Corrected color */
}

.brand-image-container {
  padding: 25px 10px;
  text-align: center; /* Center the image */
}

.brand-image {
  width: 100%;
  height: auto;
}

.main-text-container {
  padding: 50px 10%;
  text-align: center;
  background-color: white;
}

@media (max-width: 768px) {
  .carousel-item img {
    width: 100%;
    height: auto;
  }

  .carousel-caption {
    font-size: 0.9rem;
    padding: 0.5rem;
    max-width: 90%;
  }

  .modal-dialog {
    width: 90%;
  }

  .main-text-container {
    padding: 20px 5%; /* Adjusted for smaller screens */
  }
}
