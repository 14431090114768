/* custom-card.css */

.custom-card {
    height: auto; /* Set your desired height */
    display: flex;
    flex-direction: column;
  }
  
  .custom-card-img-top {
    height: 60%; /* Adjust the image height */
    object-fit: cover; /* This ensures that the image covers the area without stretching */
  }
  
  .custom-card-body {
    height: 40%; /* Remaining height for the card body */
    overflow: auto; /* Adds a scrollbar if content overflows */
  }
  
  /* Use media queries to adjust the card layout and sizes for different screens */

@media (max-width: 768px) {
  .custom-card {
    /* Example to adjust padding and font sizes for smaller screens */
    padding: 0.5rem;
  }

  .custom-card-img-top {
    /* Example to adjust image sizes for smaller screens */
    height: auto;
  }

  .custom-card-body {
    padding: 0.5rem;
  }
}

/* You can also adjust button sizes and other elements within the card */
.filter-sidebar {
  /* Styles for desktop sidebar */
}

.filter-group {
  margin-bottom: 1rem;
}

.filter-title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  text-transform: uppercase;
  padding: 0.5em 0;
  cursor: pointer;
}

.filter-title::after {
  content: '+';
  float: right;
  font-weight: bold;
}

.filter-group .collapse {
  padding-left: 1em;
}

.filter-group .collapse.show .filter-title::after {
  content: '-';
}

.form-check {
  display: block;
  margin: 0.5em 0;
}

.form-check-input {
  margin-right: 0.5em;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .filter-btn {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-sidebar {
    display: none; /* Hide sidebar by default on mobile */
  }

  .filter-modal .filter-sidebar {
    display: block; /* Display sidebar in the modal */
  }

  /* ... other mobile specific styles ... */
}



/* Hide the default checkbox */
