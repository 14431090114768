body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom CSS file or <style> tag */

.navbar-collapse.collapsing,
.navbar-collapse.show {
  background-color: white; /* or any color you prefer */
  border: 1px solid black; /* Adds a black border */

}

.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the brand container takes up the full height of the navbar */
}

.brand-logo {
  max-height: 6vh; /* Reduced from 70% to a fixed viewport height to ensure it fits */
  width: auto; /* Maintains the aspect ratio */
}

@media (max-width: 576px) {
  .navbar-brand {
    justify-content: flex-start; /* Aligns the logo to the start (left) on smaller screens */
  }
  .brand-logo {
    max-height: 8vh; /* Further reduces the height on smaller screens */
  }
}
.navbar {
  background-color: white;  
  border-bottom: 1px solid blue; 
  height: 12vh;
  font-size: 24pt;
  }

@media (max-width: 992px) {
  .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-left: 0; /* Ensures padding doesn't affect centering */
  }

 
  .navbar-nav .nav-item,
  .navbar-nav .nav-link {
    width: 100%;
    display: block;
  }

  /* Ensuring !important is used to override any existing !important styles */
  .nav-cart-item {
    justify-content: center !important; /* Centers horizontally for flex items */
    align-items: center !important; /* Centers vertically for flex items */
    display: flex !important; /* Ensures display flex is applied */
  }

  /* Adjusts the anchor tag within the cart item if necessary */
  .nav-cart-item .nav-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0.5rem 1rem !important; /* Adjust padding as needed */
  }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/*THIS IS CSS MADE AFTER INSTALLATION*/
/* Add this in your App.css or a separate CSS file */

.product-grid {
  display: flex;
  flex-wrap: wrap;
}

.product-card {
  border: 1px solid #ccc;
  margin: 15px;
  padding: 15px;
  width: calc(33.333% - 30px);
}

/* Header Styles */
header {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

header h1 {
  margin: 0;
}

header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

header nav ul li {
  margin-left: 20px;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

header nav ul li a:hover {
  text-decoration: underline;
}

/* Footer Styles */
footer {
  background-color: #222;
  color: white;
  text-align: center;
  padding: 10px;
}

/* Product Listing */
.product-grid {
  display: flex;
  flex-wrap: wrap;
}

.product-box {
  flex: 1 1;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  text-align: center;
}

/* Buttons */
button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Shopping Cart */
.cart-list {
  list-style: none;
  padding: 0;
}

.cart-item {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

/* Login Form */
.login-form {
  max-width: 300px;
  margin: auto;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
}

/* custom-card.css */

.custom-card {
    height: auto; /* Set your desired height */
    display: flex;
    flex-direction: column;
  }
  
  .custom-card-img-top {
    height: 60%; /* Adjust the image height */
    object-fit: cover; /* This ensures that the image covers the area without stretching */
  }
  
  .custom-card-body {
    height: 40%; /* Remaining height for the card body */
    overflow: auto; /* Adds a scrollbar if content overflows */
  }
  
  /* Use media queries to adjust the card layout and sizes for different screens */

@media (max-width: 768px) {
  .custom-card {
    /* Example to adjust padding and font sizes for smaller screens */
    padding: 0.5rem;
  }

  .custom-card-img-top {
    /* Example to adjust image sizes for smaller screens */
    height: auto;
  }

  .custom-card-body {
    padding: 0.5rem;
  }
}

/* You can also adjust button sizes and other elements within the card */
.filter-sidebar {
  /* Styles for desktop sidebar */
}

.filter-group {
  margin-bottom: 1rem;
}

.filter-title {
  font-size: 1.2em;
  font-weight: bold;
  text-align: left;
  background: none;
  border: none;
  width: 100%;
  text-transform: uppercase;
  padding: 0.5em 0;
  cursor: pointer;
}

.filter-title::after {
  content: '+';
  float: right;
  font-weight: bold;
}

.filter-group .collapse {
  padding-left: 1em;
}

.filter-group .collapse.show .filter-title::after {
  content: '-';
}

.form-check {
  display: block;
  margin: 0.5em 0;
}

.form-check-input {
  margin-right: 0.5em;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .filter-btn {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .filter-sidebar {
    display: none; /* Hide sidebar by default on mobile */
  }

  .filter-modal .filter-sidebar {
    display: block; /* Display sidebar in the modal */
  }

  /* ... other mobile specific styles ... */
}



/* Hide the default checkbox */

.carousel-container {
  padding-top: 8vh;
  padding-bottom: 5vh;
  text-align: center;
  width: 100%;
  background-color: white; /* Corrected color */
}

.brand-image-container {
  padding: 25px 10px;
  text-align: center; /* Center the image */
}

.brand-image {
  width: 100%;
  height: auto;
}

.main-text-container {
  padding: 50px 10%;
  text-align: center;
  background-color: white;
}

@media (max-width: 768px) {
  .carousel-item img {
    width: 100%;
    height: auto;
  }

  .carousel-caption {
    font-size: 0.9rem;
    padding: 0.5rem;
    max-width: 90%;
  }

  .modal-dialog {
    width: 90%;
  }

  .main-text-container {
    padding: 20px 5%; /* Adjusted for smaller screens */
  }
}

footer {
    background-color: #222;
    color: white;
    text-align: center;
    padding: 10px;
    margin-top: auto; /* Pushes footer to bottom */
  }
  
  @media (max-width: 768px) {
    .footer .container .row .col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .footer .container .row .col .p-3 {
      padding: 1rem !important; /* Adjust padding */
    }
  
    /* Adjust footer text size and spacing for smaller screens */
    .footer a {
      font-size: 14px; /* Smaller font size */
      display: block; /* Stack the links */
      margin-bottom: 0.5rem; /* Space between links */
    }
  }
  
  /* Optional: Adjust the main content padding or margin if needed */
  .main-content {
    padding: 1rem;
  }
